.dropdown-menu{
  padding-bottom: 0;
}

.form-check{
  padding: 8px 12px 8px 12px;
  &:hover{
    background-color: $grey-50;
  }
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 25px;
  font-weight: unset !important;
}

.custom-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox-box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid $primary-orange;
  border-radius: 2.5px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.custom-checkbox-input:checked + .custom-checkbox-box {
  background-color: $primary-orange;
  border-color: $primary-orange;
}

.custom-checkbox-input:checked + .custom-checkbox-box:after {
  display: block;
}

.custom-checkbox-box:after {
  left: 3.9px;
  top: 0px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
