
.modal-history.w100{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.inner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
.rotate {
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}

div.modal-history{
    position: inherit;

    .modal-history-title{
        background-color: $primary-orange;
        color: white;
        border-radius: 5px;
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        box-shadow: -1px 1px 5px 0px black;
        &:hover{
            background-color: $primary-orange-light;
            cursor: pointer;
        }
        h2{
            padding: 0px 3rem;
            margin: 0;
        }
    }

    .modal-history-content.show{
        display: block;
    }
}