.custom-select-container {
        display: inline-flex;
        position: relative;
        font-size: 0.857rem;
        margin: auto auto;

        .custom-select-text-wrapper{
            padding: 16px 0px 16px 8px;

            .selected-text {
                padding: 8px 16px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 100px;
                background-color: #fff;
                &:not(.arrowIcon):after {
                    --svg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.2345 3.4845C1.26933 3.44958 1.31071 3.42187 1.35627 3.40296C1.40183 3.38406 1.45067 3.37433 1.5 3.37433C1.54932 3.37433 1.59817 3.38406 1.64372 3.40296C1.68928 3.42187 1.73066 3.44958 1.7655 3.4845L6 7.71975L10.2345 3.4845C10.2694 3.44963 10.3108 3.42198 10.3563 3.40311C10.4019 3.38424 10.4507 3.37452 10.5 3.37452C10.5493 3.37452 10.5981 3.38424 10.6437 3.40311C10.6892 3.42198 10.7306 3.44963 10.7655 3.4845C10.8004 3.51936 10.828 3.56076 10.8469 3.60631C10.8658 3.65187 10.8755 3.70069 10.8755 3.75C10.8755 3.79931 10.8658 3.84813 10.8469 3.89369C10.828 3.93924 10.8004 3.98063 10.7655 4.0155L6.2655 8.5155C6.23066 8.55042 6.18928 8.57813 6.14372 8.59703C6.09817 8.61594 6.04932 8.62567 6 8.62567C5.95067 8.62567 5.90183 8.61594 5.85627 8.59703C5.81072 8.57813 5.76933 8.55042 5.7345 8.5155L1.2345 4.0155C1.19958 3.98066 1.17187 3.93928 1.15296 3.89372C1.13406 3.84817 1.12433 3.79932 1.12433 3.75C1.12433 3.70067 1.13406 3.65183 1.15296 3.60627C1.17187 3.56071 1.19958 3.51933 1.2345 3.4845Z" fill="%23574D4D"/></svg>');
                    content: var(--svg);
                    margin-top: 4px;
                    margin-left: 5px;
                    color: $grey-300;
                }
                &:hover{
                    cursor: pointer;
                    font-weight: 500;
                    color: #B3ACA9;
                    background-color: $grey-50;
                }
                
                // &.active::after {
                //     top: 8px;
                //     border-color: transparent transparent #fff transparent;
                // }

                &.arrowIcon i{
                    color: #B3ACA9;
                    margin-left: 6px;
                    font-size: 1rem;
                }

                &.border{
                    border-radius: 4px;
                    border: 1px solid #F1F0F0 !important;
                }
            }
        }

        
        ul.select-options {
            margin: 0;
            margin-top: 45px;
            padding: 0;
            position: absolute;
            z-index: 9999;
            width: 100%;
            border-radius: var(--Radius-radii-xs, 4px);
            border: 1px solid #F1F0F0;
            background: #FFF;
            box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);

            li {
                list-style-type: none;
                padding: 8px 26px 8px 16px;
                cursor: pointer;
                font-weight: 400;
                color: #242322;
                line-height: 20px;
                letter-spacing: 0.05px;

                &:hover {
                    font-weight: 600;
                    color: #B3ACA9;
                    background-color: $grey-50;
                }
            }
            
        }
        
}

